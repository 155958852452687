
import { collection, query, where, getDocs, doc, getDoc } from "firebase/firestore";
import {db} from "@/firebase"

export async function getEntity(brandName) {
    let data
    const q = query(collection(db, "entities"), where("slug", "==", brandName))
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        data = doc.data()
    });
    return data
}

export async function getEntityItems(brandName) {
    let id
    let arr = []

    const q = query(collection(db, "entities"), where("slug", "==", brandName))

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        id = doc.id
    });
    const ref = query(collection(db, "entities/" + id + "/items"), where("img", "!=", null))
    const snapshot = await getDocs(ref);
    snapshot.forEach((doc) => {
        let data = {}
        data = doc.data()
        data.uuid = doc.id
        arr.push(data)
    });
    return arr
}

export async function getEntityItem(brandName, uuid) {
    let id
    const q = query(collection(db, "entities"), where("slug", "==", brandName))

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        id = doc.id
    });
    const ref = doc(db, "entities/" + id + "/items", uuid);
    const docSnap = await getDoc(ref);
    let data
    if (docSnap.exists()) {
        // Convert to City object
        data = docSnap.data();
    } else {
        console.log("No such document!");
    }
    return data
}