<template>
  <div class="h-100 d-flex flex-column flex-md-row col-md-12 col-lg-8 item">
    <div class="flex-column col-md-6 justify-content-center flex-grow-1 overflow-auto img-div" :style="{ backgroundImage: 'url(' + data.img + ')', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover' }">
    </div>
    <div class="flex-column col-md-6 justify-content-center pt-0 p-3 px-md-5">
      <div class="row h-100 align-content-between align-content-md-start">
        <div>
          <div class="price text-start d-none d-md-block back" @click="this.$router.back()">Back</div>
          <div class="pt-4 pb-5">
            <div class="title text-start">{{data.title}}</div>
            <div class="price text-start pt-2" v-if="data.price">€ {{data.price}}</div>
          </div>
        </div>
        <div>
          <div class="d-flex">
          <div id="mody" class="text-start"></div>
          </div>
          <div class="size-table text-start mt-2 mb-2 d-flex align-items-center">
            <div class="d-flex justify-content-center align-items-center">
              <img src="@/assets/img/clotheshanger.svg" class="icon pe-2" alt="">
              <span class="size-text">Size guide</span>
            </div>
          </div>
          <div class="py-2 text-start d-flex flex-wrap">
            <div class="pe-1 mb-1" v-for="s in data.sizes" :key="s">
              <span class="size-list">{{s.size}}</span>
            </div>
          </div>
          <button class="size">
            ADD TO CART
          </button>
          <div class="d-none d-md-block">
          <div class="w-100 justify-content-center d-flex">
            <div class="note note-selected">Shipping</div>
            <div class="note">Description</div>
            <div class="note">Materieals</div>
          </div>
          <div class="py-3 px-2">
            <p style="font-size: 0.8rem" class="text-start">
              Standard delivery is free on all orders containing at least one product from the New Collection.
              Express Shipping is subject to a fee.
              Check out the FAQs to find out more.
            </p>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getEntityItem} from "@/utilities";

export default {
  data() {
    return {
      data: {}
    }
  },
  mounted() {
    let mody = document.createElement('script')
    switch(process.env.VUE_APP_MODY_ENV) {
      case 'mody-p':
        mody.setAttribute('src', 'https://cdn.mody.ai')
        break
      case 'mody-s':
        mody.setAttribute('src', 'https://staging.cdn.mody.ai')
        break
      default:
        mody.setAttribute('src', 'https://dev.cdn.mody.ai')
    }
    mody.setAttribute("data-mody","")
    mody.setAttribute("data-tag","mody")
    mody.setAttribute("data-entity",this.$route.params.brand)
    mody.setAttribute("data-item",this.$route.params.product)
    mody.setAttribute("defer","defer")
    const existingScript = document.querySelector('script[data-mody]');
    if (!existingScript) {
      document.head.appendChild(mody)
    }
  },
  async created() {
    this.data = await getEntityItem(this.$route.params.brand, this.$route.params.product)
  },
}

</script>

<style lang="scss" scoped>
#mody {
  font-size: 0.8rem;
}
.back {
  cursor: pointer;
}
.item {
  margin: auto;
}
.title{
  font-weight: bold;
  font-size: 1.2rem;
}
.price {
  font-weight: normal;
  font-size: 1rem;
}
.my-container{
  overflow: hidden;
  height: 100%;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  justify-content: space-between;
}
.img-container{
  height: 500px;
  background-size: cover;
  background-position: center;
}
img{
  height: 100%;
  max-width: 100%;
}
.img-div {
  //border-bottom-right-radius: 24px;
  //border-bottom-left-radius: 24px;
}
.size-text {
  font-size: 1rem;
}
.size{
  margin-top: 8px;
  background-color: rgb(52,52,51);
  color: white;
  border: 1px solid black;
  padding: 16px 30px;
  width: 100%;
  letter-spacing: 0.05rem;
  //border-radius: 16px;
  font-size: 1rem;
}
.icon{
  height: 16px;
  float: left;
}
.size-list{
  padding: 16px;
  font-size: 1rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #eee;
  display: flex;
  //border-radius: 8px;
  align-items: center;
  justify-content: center;
}

.size-table{
  font-size: 0.8rem;
}
.note{
  border: none;
  border-bottom: 1px solid #000;
  color: #000;
  background: none;
  padding: 10px 20px;
  margin-top: 5px;
  font-size: 1rem;
}
.note-selected{
  border-bottom: 3px solid #000;
  font-weight: 700;
}
@media (min-width: 768px) {
  .title{
    font-weight: bold;
    font-size: 1.2rem;
  }
  .price {
    font-size: 1rem;
  }
  .item{
    padding: 30px;
  }
}
@media (max-width: 767px) {

}
</style>