import { createRouter, createWebHistory } from 'vue-router'
import ProductList from '../views/ProductList.vue'
import Product from '../views/ProductDetails.vue'
// import Test from '../views/TestMody'

const routes = [
   {
     path: '/',
     redirect: '/mxf'
   },
   {
     path: '/:brand',
     name: 'list',
     component: ProductList
   },
   {
     path: '/:brand/:product',
     name: 'product',
     component: Product
   },
   {
     path: '/:pathMatch(.*)*',
     redirect: '/' // Redirect to the default route
   }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
