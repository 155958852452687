<template>
  <div class="container-fluid d-flex flex-column pb-4">
    <div class="row g-0">
      <div class="col">
        <img v-if="entity" :src="entity.config.logo" alt="logo" class="logo mt-3 mb-3">
      </div>
    </div>
    <div class="row g-0 flex-fill align-content-start">
      <div class="row row-cols-2 row-cols-md-3 pa-5 g-0" v-if="items.length > 2">
        <div class="col d-flex justify-content-center" v-for="p in items" :key="p.uuid">
        <div class="card" @click="goToProduct(p.uuid)">
          <img :src="p.img" class="card-img-top" alt="...">
          <div class="row card-body p-2 pt-1 pb-1">
            <div class="col-12">
              <h5 class="card-title m-auto text-center">{{p.title}}</h5>
            </div>
            <div class="col-12">
              <p class="card-text text-center" v-if="p.price">€ {{p.price}}</p>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div class="row row-cols-1 row-cols-md-3 gy-2 justify-content-center" v-else>
      <div class="col-12 d-flex justify-content-center" v-for="p in items" :key="p.uuid">
        <div class="card" @click="goToProduct(p.uuid)">
          <img :src="p.img" class="card-img-top" style="max-width: 65%; margin: auto" alt="...">
          <div class="row card-body">
            <div class="col-12">
              <h5 class="card-title text-center">{{p.title}}</h5>
            </div>
            <div class="col-12">
              <p class="card-text text-center" v-if="p.price">€ {{p.price}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>

import { getEntityItems, getEntity } from "@/utilities";


export default {
  name: 'ProductList',
  data() {
    return {
      entity: null,
      items: []
    }
  },
  async created() {
    this.entity = await getEntity(this.$route.params.brand)
    if (!this.entity) {
      // Redirect to the default route if entity is undefined
      window.location = "/"
      return; // Stop execution
    }
    this.items = await getEntityItems(this.$route.params.brand)
  },
  methods: {
    goToProduct(uuid) {
      const currentUrl = window.location.href;
      window.location.href = currentUrl + '/' + uuid;
    }
  }
}
</script>

<style lang="scss" scoped>

.card{
  cursor: pointer;
  text-align: left;
  border-radius: 0;
  border: 0;
}
.logo{
  max-height: 60px;
  padding: 0;
}

.card-title{
  font-weight: bold;
  font-size: 1rem;
}
.card-text{
  font-weight: normal;
  font-size: 1rem;
  text-align: center;
}
.card-img-top{
  //border-radius: 48px;
  padding: 16px;
}

.accyourate-logo {
  max-width: 300px;
}

.aereonautica-logo {
  max-width: 200px;
}

@media only screen and (max-width: 600px) {
  .card{
    width: 100%;
  }
}
@media only screen and (min-width: 600px) {
  .card{
    width: 100%;
  }
}
</style>
